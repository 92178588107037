import styled from 'styled-components';

export default styled.div`
  font-family: ffvCorporateFat;
  font-weight: 100;
  font-size: ${props => props.fontSize || '1.2rem'};
  line-height: 1.5;
  margin: 4rem auto;
  & ul {
    list-style-position: inside;
  }
  & h2 {
    font-size: 1.7rem;
    text-align: center;
    font-family: ffvGaramond, serif;
    font-weight: 100;
    padding-top: 2rem;
  }
`;
